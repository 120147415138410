import React, { useState, useContext } from 'react';
import axios, { AxiosError } from 'axios';

import AuthContext from '../../utils/auth/AuthContext';
import { verifyToken } from '../../utils/auth/TokenMethod';

const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

const AuthComponent: React.FC = () => {
  const [email, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { authentication, setAuthentication } = useContext(AuthContext);
  const handleLogin = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/auth/jwt/create`, {
        email,
        password,
      }, { withCredentials: true });
      if (response.status === 401) {
        setErrorMessage("メールアドレスかパスワードが間違っています。");
      }
      
      const result = await verifyToken();
      if (result === true) {
        setAuthentication(true);
      } else if (result === false) {
        setErrorMessage("通信に失敗しました。しばらくしてから再度お試しください。");
        setAuthentication(false);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error(axiosError);
      if (axiosError.response && axiosError.response.status === 401) {
        setErrorMessage("メールアドレスかパスワードが間違っています。");
      } else {
        setErrorMessage("ログイン中にエラーが発生しました。");
      }
    }
  };
  return (
    <div className='mainSection'>
      <form onSubmit={handleLogin}>
        <div className="px-2 py-3 border-bottom">
          <p className="bg-white d-inline-block fs-subtitle">メールアドレス</p>
          <input
            className="form-control"
            type="text"
            placeholder="e-Mail"
            value={email}
            name="email"
            onChange={e => setMail(e.target.value)}
          />
        </div>
        <div className="px-2 py-3 border-bottom">
          <p className="bg-white d-inline-block fs-subtitle">パスワード</p>
          <input
            className="form-control"
            type="password"
            placeholder="Password"
            value={password}
            name="password"
            onChange={e => setPassword(e.target.value)}
          />
        </div>
        <div className='text-danger text-center mt-2'>{errorMessage}</div>
        <input className="btn btn-primary min-w-200 mx-auto mt-3 mb-2 px-2 py-1" type="submit" value="ログイン" />
        <p className="text-center"><a className="bg-white link-secondary"
          href="/password-reset">パスワードを忘れた方はこちら</a></p>
        <a className="btn btn-secondary min-w-200 mx-auto mt-5 mb-2 px-2 py-1" href="/register">新規会員登録</a>
      </form>
    </div>
  );
};

export default AuthComponent; 