import React from 'react';
import { Oval } from 'react-loader-spinner';


export const LoadingScreen: React.FC = () => {
    return (
        <div className='d-flex justify-content-center align-items-center min-h-80vh'>
            <Oval
                height={200}
                width={200}
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#4fa94d"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />
        </div>
    );
}

export default LoadingScreen;
