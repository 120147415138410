import axios from 'axios';

const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

export interface ProductStock {
  id: number;
  name: string;
  stock: number;
  rarity: string;
  possessions: number;
}

export const getProductStock = async (productCode: string): Promise<ProductStock[]> => {
  const postData = { product_code: productCode };
  const response = await axios.post(`${apiUrl}/maintenance/getProductStockList/`, postData, { withCredentials: true });
  return response.data.product;
}

export const getUserStock = async (productCode: string): Promise<ProductStock[]> => {
  const postData = { product_code: productCode };
  const response = await axios.post(`${apiUrl}/maintenance/getProductUserStockList/`, postData, { withCredentials: true });
  return response.data.product;
}