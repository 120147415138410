import React from 'react';;

type TokenContextType = {
  authentication: boolean;
  setAuthentication: React.Dispatch<React.SetStateAction<boolean>>;
  authErrorFlag: boolean;
  setAuthErrorFlag: React.Dispatch<React.SetStateAction<boolean>>;
  authLoadingFlag: boolean;
  setAuthLoadingFlag: React.Dispatch<React.SetStateAction<boolean>>;
};

const defaultValues: TokenContextType = {
  authentication: false,
  setAuthentication: () => { },
  authErrorFlag: false,
  setAuthErrorFlag: () => { },
  authLoadingFlag: true,
  setAuthLoadingFlag: () => { },
};

const AuthContext = React.createContext<TokenContextType>(defaultValues);

export default AuthContext;