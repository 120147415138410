import axios from 'axios';

const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

interface ProductVariation {
  id: number;
  name: string;
  is_stock: boolean;
}

interface ProductVariationGroup {
  lottery_info: number;
  products: ProductVariation[];
}

interface Product {
  id: number;
  type: string;
  team: number;
  team_name: string;
  is_sale: boolean;
  product_variations: Record<string, ProductVariationGroup>;
}

export interface ProvisionRatio {
  product: Product;
}

export const getProductValiationList = async (product_code: string): Promise<ProvisionRatio> => {
  const postData = { product_code: product_code };
  const response = await axios.post(`${apiUrl}/getProductValiationList/`, postData, { withCredentials: true });
  return response.data;
}