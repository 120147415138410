import React, { useState, useEffect, FormEvent } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { LoadingScreen } from '../loading/LoadingScreen'
import { getProducts, Product } from '../../utils/getData/Products';
import { getProductStock, getUserStock, ProductStock } from '../../utils/getData/ProductStock';
import { ProductItem } from '../../subComponents/management/ProductItem';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

const StockManagement: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [activeTabKey, setActiveTabKey] = useState('salesStock');
  const [selectedTeam, setSelectedTeam] = useState("");
  const [productList, setProductList] = useState<Product[]>();
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [productStockList, setProductStockList] = useState<ProductStock[]>();
  const [productUserStockList, setProductUserStockList] = useState<ProductStock[]>();
  const [isSale, setIsSale] = useState<boolean>(true);

  var teamList: Object;
  if (typeof process.env.REACT_APP_TEAMNAMES === "string") {
    teamList = JSON.parse(process.env.REACT_APP_TEAMNAMES);
  } else {
    teamList = [];
  }

  const handleSelectTab = (key: string | null) => {
    if (key === null) {
      return;
    }
    setProductStockList([]);
    setProductUserStockList([]);
    setActiveTabKey(key);
  };

  const handleChangeProduct = (productCode: string) => {
    if (productList !== undefined) {
      const product = productList.find(p => p.product_code === productCode)
      if (product !== undefined) {
        setSelectedProduct(product)
        setIsSale(product.is_sale);
        setProductStockList([]);
        setProductUserStockList([]);
      }
    }
  }

  const fetchProductData = async (teamName: string) => {
    setLoading(true);
    setSelectedTeam(teamName);
    // 選択済みのプロダクトをリセット
    setSelectedProduct(undefined);
    setProductStockList(undefined);
    setProductUserStockList([]);
    try {
      const response = await getProducts(teamName);
      setProductList(response);
      setLoading(false);
    } catch {
      setError("データの取得に失敗しました。ページをリロードしてください。");
    }
  }

  const fetchProductStockData = async (productCode: string) => {
    setLoading(true);
    try {
      const response = await getProductStock(productCode);
      setProductStockList(response);
      setLoading(false);
    } catch {
      setError("データの取得に失敗しました。ページをリロードしてください。");
    }
  }

  const fetchProductUserStockData = async (productCode: string) => {
    setLoading(true);
    try {
      const response = await getUserStock(productCode);
      setProductUserStockList(response);
      setLoading(false);
    } catch {
      setError("データの取得に失敗しました。ページをリロードしてください。");
    }
  }

  const updateIsSale = async (productCode: string) => {
    setLoading(true);
    try {
      const postData = { product_code: productCode };
      const response = await axios.post(`${apiUrl}/maintenance/updateProductIsSale/`, postData, { withCredentials: true });
      setIsSale(response.data.is_sale);
      if (response.status === 200) {
        await fetchProductData(selectedTeam);
        if (selectedProduct !== undefined) {
          await fetchProductStockData(selectedProduct.product_code);
        }
      }
      if (response.data && typeof response.data.is_sale === 'boolean') {
        setIsSale(response.data.is_sale);
      }
      // TODO update成功時の通知
      setLoading(false);
    } catch (error) {
      // TODO error時の処理
      setError("データの更新に失敗しました。ページをリロードしてください。");
    }
  }

  useEffect(() => {
    setLoading(false);
  }, []);

  if (error !== "") {
    return <div>{error}</div>
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="mainSection">
      <div className='d-flex mb-3'>
        <div>
          <p className='m-0 ms-1'>チーム</p>
          <select className="form-control w-350" name="team" defaultValue={selectedTeam} onChange={e => fetchProductData(e.target.value)}>
            <option value=""></option>
            {
              Object.entries(teamList).map(([key, value], index) => (
                <option key={index} value={key}>{value}</option>
              ))
            }
          </select>
        </div>
        <div>
          <p className='m-0 ms-1'>製品</p>
          <select className="form-control w-350" name="product" defaultValue={selectedProduct?.product_code} onChange={e => handleChangeProduct(e.target.value)} >
            <option value=""></option>
            {
              productList?.map((product, index) => (
                <option key={product.product_code} value={product.product_code}>
                  {product.name}
                </option>
              ))
            }
          </select>
        </div>
      </div>
      <Tabs
        id="controlled-tab-delivery-management"
        className="mb-3"
        onSelect={handleSelectTab}
        activeKey={activeTabKey}
        fill
      >
        <Tab eventKey="salesStock" title="販売在庫管理">
          <div className='d-flex justify-content-end mt-2'>
            {selectedProduct && productStockList?.length !== 0 &&
              <div>
                <Button onClick={() => updateIsSale(selectedProduct.product_code)} >メンテナンス状況切り替え</Button>
                <p>現在の販売状況：{isSale ? "販売中" : "販売停止中"}</p>
              </div>
            }
            {selectedProduct &&
              <div className='ms-3'>
                <Button onClick={() => fetchProductStockData(selectedProduct?.product_code)}>データ取得</Button>
              </div>
            }
          </div>
          <div className="stockTable">
            {productStockList?.map(product => (
              <ProductItem key={product.id} productValiation={product} team={selectedTeam} productCode={selectedProduct?.product_code ?? ''} isSale={selectedProduct !== undefined ? isSale : false} setLoading={setLoading} fetchProductStockData={fetchProductStockData} />
            ))}
          </div>
        </Tab>
        <Tab eventKey="userStock" title="ユーザー所持数管理">
          <div className='d-flex justify-content-end mt-2'>
            {selectedProduct &&
              <div className='ms-3'>
                <Button onClick={() => fetchProductUserStockData(selectedProduct?.product_code)}>データ取得</Button>
              </div>
            }
          </div>
          <div className="stockTable">
            {productUserStockList?.map(product => (
              <ProductItem key={product.id} productValiation={product} team={selectedTeam} productCode={selectedProduct?.product_code ?? ''} isSale={selectedProduct !== undefined ? isSale : false} setLoading={setLoading} fetchProductStockData={fetchProductStockData} />
            ))}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default StockManagement; 