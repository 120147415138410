import axios from 'axios';

const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

export interface Product {
  name: string;
  product_code: string;
  product_name: string;
  team_name: string;
  is_sale: boolean;
}

export const getProducts = async (teamName: string): Promise<Product[]> => {
  const postData = { team_name: teamName };
  const response = await axios.post(`${apiUrl}/getProductList/`, postData, { withCredentials: true });
  return response.data.products;
}