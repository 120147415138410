import React, { useState, useEffect } from 'react';
import { getCurrentUser } from '../../utils/getData/User';
import { LoadingScreen } from '../loading/LoadingScreen';
import Logout from '../auth/Logout';
import { TicketForm } from './TicketForm';
import { TicketConfirm } from './TicketConfirm';
import { TicketFormComplete } from './TicketFormComplete';
import { createOrderId } from '../../utils/purchase/CreateOrderId';

const Ticket: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("first");
  const [possessionTickets, setPossessionTickets] = useState(0);
  const [buyTickets, setBuyTickets] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState("Creditcard");
  const [accessID, setAccessID] = useState("");
  const [accessPass, setAccessPass] = useState("");
  const [orderId] = useState(createOrderId());
  const price: number = Number(process.env.REACT_APP_TICKET_PRICE);

  useEffect(() => {
    // useEffectのコールバック関数内で直接awaitを使用することはできないので、非同期関数を定義して呼び出し
    const fetchUserTickets = async () => {
      // TODO エラーハンドリングを行う
      try {
        const user = await getCurrentUser();
        setPossessionTickets(user.tickets);
        setLoading(false);
      } catch {
        return <Logout reason="通信に失敗しました。再度ログインしてください。この画面が繰り返し表示される場合、Cookieを削除してください。" />
      }
    }
    fetchUserTickets();
  }, []);

  let content;
  switch (status) {
    case 'first':
      content = (
        <TicketForm possessionTickets={possessionTickets} price={price} orderId={orderId} setStatus={setStatus} buyTickets={buyTickets} setBuyTickets={setBuyTickets} setPaymentMethod={setPaymentMethod} setAccessID={setAccessID} setAccessPass={setAccessPass}></TicketForm>
      );
      break;
    case 'confirm':
      content = (
        <TicketConfirm buyTickets={buyTickets} orderId={orderId} paymentMethod={paymentMethod} accessID={accessID} accessPass={accessPass} setStatus={setStatus}></TicketConfirm>
      );
      break;
    case 'complete':
      content = (
        <TicketFormComplete possessionTickets={possessionTickets} buyTickets={buyTickets} />
      );
      break;
    default:
      content = <div>Unknown status</div>;
      break;
  }

  if (loading) {
    return <LoadingScreen></LoadingScreen>;
  }

  return (
    <div className="s-reset">
      {content}
    </div>
  );
};

export default Ticket; 