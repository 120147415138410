import React, { useState } from 'react';
import axios, { AxiosError } from 'axios';
import { validateEmail } from '../../utils/validate/Validate';
import LoadingScreen from '../loading/LoadingScreen';
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

const PasswordResetRequestFormComponent: React.FC = () => {
  const [email, setMail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState("form");
  const submit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    setStatus("loading");
    try {
      const response = await axios.post(`${apiUrl}/password_reset/`, {
        email,
      }, { withCredentials: true });
      if (response.status === 200) {
        setStatus("complete");
      } else if (response.status === 401) {
        setStatus("form");
        setMessage("このメールアドレスは登録されていません。");
      }

    } catch (error) {
      const axiosError = error as AxiosError;
      setStatus("form");
      if (axiosError.response && axiosError.response.status === 400) {
        setMessage("このメールアドレスは登録されていません。");
      } else {
        setMessage("エラーが発生しました。ページを読み込みなおして再度お試しください。");
      }
    }
  };
  let content;
  switch (status) {
    case 'form':
      content = (
        <>
          <p className="topTitle">パスワード変更申請</p>
          <div className="px-1 mainSection">
            <p className="fs-caption m-2">
              パスワード再設定用URLを送信します。<br />
              ご登録いただいているメールアドレスを入力し「送信する」ボタンを押してください。
            </p>
            <form onSubmit={submit} className="m-2">
              <div className='m-1 mt-3 p-2 border-top'>
                <p className="fs-subtitle">メールアドレス</p>
                <input
                  className='form-control'
                  type="text"
                  placeholder="e-Mail"
                  value={email}
                  name="email"
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setMessage("");
                    setMail(newValue);
                    setMessage(validateEmail(newValue));
                  }}
                />
                <div className='text-danger'>{message}</div>
                <input className='btn btn-primary min-w-200 mx-auto m-2' disabled={message ? true : false} type="submit" value="送信する" />
              </div>
            </form>
          </div>
        </>
      )
      break;
    case 'complete':
      content = (
        <>
          <p className="topTitle">パスワード変更申請</p>
          <div className="px-1 mainSection">
            <p className="fs-description mb-3">
              パスワード再設定手続きを承りました。
            </p>
            <p className="fs-description mb-3">
              メールを送信しました。メールに記載されているURLからパスワード変更を行ってください。
            </p>
            <p className="fs-description mb-3">
              メールが届かない場合は、迷惑メールフォルダーなどに振り分けられてしまう場合がありますので、すべてのメールをご確認ください。
            </p>
          </div>
        </>
      )
      break;
    case 'loading':
      content = (
        <LoadingScreen />
      )
      break;
    default:
      content = <div>Unknown status</div>;
      break;
  }
  return (
    <>
      {content}
    </>
  );
};

export default PasswordResetRequestFormComponent; 