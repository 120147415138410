import axios from 'axios';
import { useContext, useEffect, useState, } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../utils/auth/AuthContext';
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

export const Logout: React.FC<{ reason: string }> = ({ reason }) => {
  const navigate = useNavigate();
  const [logoutReason, setLogoutReason] = useState('');
  const { setAuthErrorFlag } = useContext(AuthContext);
  useEffect(() => {
    const performLogout = async () => {
      try {
        document.cookie = 'accessToken=; Max-Age=0; path=/';
        document.cookie = 'refreshToken=; Max-Age=0; path=/';
        await axios.get(`${apiUrl}/auth/jwt/logout`, { withCredentials: true });
      } catch (error) {
        if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
          // 401エラーの場合の処理
          setLogoutReason('認証エラーが発生したため、ログアウトします。');
        }
      } finally {
        if (reason) {
          // 10秒後にホームページへリダイレクト
          setTimeout(() => {
            navigate('/');
            setAuthErrorFlag(false);
            window.location.reload();
          }, 10000);
        } else {
          navigate('/');
          window.location.reload();
        }
      }
    };
    performLogout();
  }, [navigate]);

  // ログアウト処理中の表示
  return (
    <div>
      {reason ?
        <>
          <p>{reason}</p>
          <p>10秒後にトップページに自動的に遷移します。</p>
        </>
        :
        <p>ログアウト中...</p>}
    </div>
  );
};

export default Logout;