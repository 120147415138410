import axios from 'axios';

const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

export interface User {
  last_name: string;
  first_name: string;
  email: string;
  postcode: string;
  prefecture: string;
  city: string;
  building: string;
  phone_number: string;
  tickets: number;
}

export const getCurrentUser = async (): Promise<User> => {
  // 現在のユーザーのデータを取得
  const response = await axios.get(`${apiUrl}/currentUser/`, { withCredentials: true });
  return response.data;
}