import React from 'react';
import './App.css';

import AuthProvider from './components/utils/auth/AuthProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import MainSection from './components/layout/MainSection'
import ScrollToTop from '../src/components/utils/ScrollToTop'
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/_common.sass';

const is_maintenance:Boolean = process.env.REACT_APP_IS_MAINTENANCE === 'true';

function App() {
  if(is_maintenance){
    return <><p>現在メンテナンス中です<div className=""></div></p></>
  }
  return (
    <Router>
      <ScrollToTop />
      <AuthProvider>
        <Header/>
        <MainSection/>
        <Footer/>
      </AuthProvider>
    </Router>
  );
}

export default App;
