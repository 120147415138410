import React from 'react';

type TicketFormCompleteProps = {
  possessionTickets: number,
  buyTickets: number
}

export const TicketFormComplete: React.FC<TicketFormCompleteProps> = ({ possessionTickets, buyTickets }) => {
  return (
    <>
      <p className="topTitle">チケット購入完了</p>

      <div className='mainSection'>
        <p className="fs-description">
          この度はオンサイトカードOnlineをご利用いただきありがとうございます。<br />
          チケットの購入が完了しました。
        </p>
        <p className="fs-description my-3">
          お客様のメールアドレス宛に自動返信の内容確認メールをお送りいたしましたので、ご確認ください。
        </p>
        <p className="fs-description mb-3">
          また、メールアドレスによっては、内容確認メールが「迷惑メールフォルダ」に入ってしまう場合があります。<br />
          迷惑メールフォルダに内容確認メールが届いていないかご確認ください。
        </p>
        <p className="fs-description mb-3">数時間経っても購入が反映されていない場合は<a target="_blank" rel="noopener"
          href="https://www.pbridge.net/contact">こちら</a>からお問い合わせをお願いします。<br />
          ※チケットの反映に時間がかかる場合があります。ご了承ください。
        </p>
        <div className="border rounded-3 text-center p-1">
          <div className="flex-between align-items-center my-2">
            <p className="fs-description">購入商品</p>
            <img className='max-w-200' src="image/ticket.png" />
            <p className="fs-description">
              オンサイトカード<br />
              購入・発送用チケット<br />
              {buyTickets}枚
            </p>
          </div>
          <div className="flex-between align-items-center my-2">
            {/* <p className="fs-description">現在のチケット所持数</p>
          <p className="fs-description">{{ $tickets }}枚</p> */}
          </div>
        </div>
      </div>
    </>
  );
};