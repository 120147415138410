import axios from 'axios';

const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

export interface ProductVariations {
  id: number;
  rarity_name: string;
  variation_name: string;
  possession: number;
  is_possession: boolean;
}

export const getAlbumData = async (product_code: string): Promise<ProductVariations[]> => {
  const postData = { product_code: product_code };
  // 現在のユーザーのデータを取得
  const response = await axios.post(`${apiUrl}/getAlbumData/`, postData, { withCredentials: true });
  return response.data;
}