import { Location } from 'react-router-dom';

export function getLocationTeam(location: Location): string {
  var teamList: Object;
  if (typeof process.env.REACT_APP_TEAMNAMES === "string") {
    teamList = JSON.parse(process.env.REACT_APP_TEAMNAMES);
  } else {
    teamList = [];
  }
  
  const pathSegments = location.pathname.split('/');
  if (pathSegments[1] in teamList) {
    return pathSegments[1].toLowerCase();
  } else {
    return "default"
  }
}
export default getLocationTeam