const price = process.env.REACT_APP_TICKET_PRICE;
const mail = process.env.REACT_APP_MAIL;
const Transaction: React.FC = () => {
  return (
    <div>
      <p className="topTitle">特定商取引法に基づく表記</p>
      <table className="mainSection mb-3 table table-bordered border-dark align-middle fs-body">
        <tbody>
          <tr>
            <td className="w-35 text-center">販売業者</td>
            <td>株式会社PBridge（法人番号：7010601048604）</td>
          </tr>
          <tr>
            <td className="w-35 text-center">通販業務責任者</td>
            <td>權アロン</td>
          </tr>
          <tr>
            <td className="w-35 text-center">所在地</td>
            <td>〒532-0002<br />大阪府大阪市淀川区東三国4-4-15<br />コラム新大阪6F</td>
          </tr>
          <tr>
            <td className="w-35 text-center">電話番号</td>
            <td>06-6395-0945</td>
          </tr>
          <tr>
            <td className="w-35 text-center">商品の価格</td>
            <td>商品詳細ページごとに表示</td>
          </tr>
          <tr>
            <td className="w-35 text-center">商品代金以外の料金の説明</td>
            <td>出荷手配料1チケット（{price}円）<br />商品輸送料・資材料・梱包料・出荷管理費用などが含まれております。</td>
          </tr>
          <tr>
            <td className="w-35 text-center">引き渡し時期</td>
            <td>通常、ご決済日より7営業日以内に発送致します。<br />
              ※一部地域の場合は、配送が遅れる場合がありますので予めご了承ください。
            </td>
          </tr>
          <tr>
            <td className="w-35 text-center">支払方法</td>
            <td>クレジットカード決済</td>
          </tr>
          <tr>
            <td className="w-35 text-center">支払期限</td>
            <td>クレジットカード<br />
              ご利用のクレジットカードの締め日や契約内容により異なります。ご利用されるカード会社までお問い合わせください。
            </td>
          </tr>
          <tr>
            <td className="w-35 text-center">返品について</td>
            <td>お客様都合での商品の返品は、承れませんので予めご了承下さい。<br />
              ただし、商品が不良品の場合やご注文と異なる商品が到着した場合のみ、返品・交換の受付をいたします。<br />
              商品到着後7日以内に上記連絡先までご連絡ください。
            </td>
          </tr>
          <tr>
            <td className="w-35 text-center">お問い合わせ先</td>
            <td>{mail}<br />上記メールアドレスまで、氏名・ご連絡先・お問合せ内容を記載の上、メールをお願い致します。</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Transaction; 