import React, { useState, useEffect } from 'react';
import { getCurrentUser } from '../../utils/getData/User';
import { getPossesionProducts, Products } from '../../utils/getData/PossesionProducts';
import { DeliveryForm } from './DeliveryForm';
import { DeliveryConfirm } from './DeliveryConfirm';
import { DeliveryComplete } from './DeliveryComplete';
import { LoadingScreen } from '../loading/LoadingScreen';

export interface FormValues {
  products: {
    [productName: string]: {
      [productVariationId: number]: Product[];
    };
  };
  postcode: string;
  address: string;
}

export interface Product {
  id: number;
  rarity_name: string;
  name: string;
  note: string;
}


const Delivery: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("first");
  const [tickets, setTickets] = useState<number>(0);
  const [productData, setProductData] = useState<Products | {}>({});
  const [formValues, setFormValues] = useState<FormValues>({
    products: {} as {
      [productName: string]: {
        [productVariationId: number]: Product[];
      };
    },
    postcode: '',
    address: ''
  });


  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await getCurrentUser();
        const products = await getPossesionProducts();
        if (user !== null) {
          setTickets(user.tickets);
          setFormValues(prev => ({ ...prev, postcode: user.postcode }))
          setFormValues(prev => ({
            ...prev,
            address: `${user.prefecture}${user.city}${user.building ? user.building : ""}`
          }));
          if (products !== null) {
            setProductData(products);
            const transformedData: FormValues['products'] = {};

            for (const productName of Object.keys(products)) {
              transformedData[productName] = {};

              products[productName].forEach((product, idx) => {
                if (!transformedData[productName][product.variation_id]) {
                  transformedData[productName][product.variation_id] = [];
                }

                for (let i = 0; i < product.possession; i++) {
                  transformedData[productName][product.variation_id].push({
                    id: product.variation_id,
                    rarity_name: product.rarity_name,
                    name: product.variation_name,
                    note: ''
                  });
                }
              });
            }

            setFormValues(prev => ({
              ...prev,
              products: transformedData
            }));

            if (user && formValues) {
              setLoading(false);
            }
          }
        };
      } catch {
        // TODO ログアウトしてトップページへ
      }
    }
    fetchData();
  }, []);

  let content;
  switch (status) {
    case 'first':
      content = (
        < DeliveryForm formValues={formValues} tickets={tickets} productData={productData} setFormValues={setFormValues} setStatus={setStatus} />
      );
      break;
    case 'confirm':
      content = (
        < DeliveryConfirm formValues={formValues} productData={productData} setStatus={setStatus} />
      );
      break;
    case 'complete':
      content = (
        <DeliveryComplete formValues={formValues} />
      );
      break;
    default:
      content = <div>Unknown status</div>;
      break;
  }

  if (loading) {
    return <LoadingScreen></LoadingScreen>;
  }

  return (
    <div className="s-reset">
      {content}
    </div>
  );
};

export default Delivery; 