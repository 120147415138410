import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

export const DeliverySearchHelpButton: React.FC = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="secondary" onClick={handleShow} className={`ms-2 btn btn-primary`}>
        ヘルプ
      </Button>

      <Modal show={show} onHide={handleClose} dialogClassName="min-w-100">
        <Modal.Header closeButton>
          <Modal.Title>ヘルプ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey="general"
            id="help-tab"
            className="mb-3"
          >
            <Tab eventKey="general" title="全般">
              <div>
                <li className='text-danger'>膨大な量の検索結果が予想されるような設定はしないでください。</li>
                <li>検索結果は降順(最近の発送から古い発送への順)で表示されます。</li>
                <li>検索結果が50件を超える場合は新しい発送から順に50件を取得します。</li>
                <li>複数の項目が入力された場合、全ての項目を満たす結果が出力されます。</li>
              </div>
            </Tab>
            <Tab eventKey="name" title="名前">
              <div>
                <li className='text-danger'>会員登録されている名前ではなく発送時にユーザーが設定した発送先名で検索します。</li>
                <li>発送先名のデフォルト値はユーザーの会員情報名字と名前を半角スペースで繋いだものです。</li>
                <li>例:田中(名字)太郎(名前)で会員登録されている場合 → 「田中 太郎」</li>
              </div>
            </Tab>
            <Tab eventKey="mail" title="メール">
              <div>
                <li>会員登録されているメールアドレスで検索します。</li>
              </div>
            </Tab>
            <Tab eventKey="time" title="発送日時">
              <div>
                <li>発送依頼日時を14桁の数値に変換した値で検索します。(年+月+日+時+分+秒)</li>
                <li>例:2024-11-01 15:01:20 → 20241101150120</li>
                <li>発送依頼時にユーザーに届くメールに注文番号として発送依頼日時が記載されています。</li>
                <li className='text-danger'>検索範囲の最大値は3日間までです。</li>
              </div>
            </Tab>

          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeliverySearchHelpButton;