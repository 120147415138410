export const createOrderId = (): string => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は0から始まるため、1を加える
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
  const randomNumber = Math.floor(Math.random() * 1000).toString().padStart(3, '0'); // ランダムな3桁の数字を生成
  return `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}${randomNumber}`;
}