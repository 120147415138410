import React from 'react';

const Error500Component: React.FC = () => {
  return (
    <div className="s-reset">
      500 error
      通信中にエラーが発生しました。
      もう一度、最初からお試しください。
    </div>
  );
};

export default Error500Component; 