import React from 'react';

const Error404Component: React.FC = () => {
  return (
    <div className="s-reset">
      404 error
      ページが見つかりませんでした。
    </div>
  );
};

export default Error404Component; 