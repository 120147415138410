import React, { useState, useEffect } from 'react';
import { LoadingScreen } from '../loading/LoadingScreen'
import { getProducts, Product } from '../../utils/getData/Products';
import { getAlbumData, ProductVariations } from '../../utils/getData/Album';
import { ProductItem } from '../../subComponents/album/ProductItem';

const Album: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [productList, setProductList] = useState<Product[]>();
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [productVariationList, setProductVariationList] = useState<ProductVariations[]>();

  var teamList: Object;
  if (typeof process.env.REACT_APP_TEAMNAMES === "string") {
    teamList = JSON.parse(process.env.REACT_APP_TEAMNAMES);
  } else {
    teamList = [];
  }


  const fetchProductData = async (teamName: string) => {
    setLoading(true);
    setSelectedTeam(teamName);
    // 選択済みのプロダクトをリセット
    setSelectedProduct(undefined);
    setProductVariationList(undefined);
    try {
      const response = await getProducts(teamName);
      setProductList(response);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }

  const fetchProductVariationData = async (productCode: string) => {
    setLoading(true);
    if (productList !== undefined) {
      const product = productList.find(p => p.product_code === productCode);
      if (product !== undefined) {
        setSelectedProduct(product);
      }
    }
    try {
      const response = await getAlbumData(productCode);
      setProductVariationList(response);
      setLoading(false);
    } catch {
      // TODO アルバムデータ取得失敗時のエラー 一つも持っていなかった場合を考慮させる
    }
  }

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="mainSection">
      <div className='d-flex justify-content-around my-2'>
        <div>
          <p className='m-0 ms-1'>チーム</p>
          <select className="form-control w-200" name="team" defaultValue={selectedTeam} onChange={e => fetchProductData(e.target.value)}>
            <option value=""></option>
            {
              Object.entries(teamList).map(([key, value], index) => (
                <option key={index} value={key}>{value}</option>
              ))
            }

          </select>
        </div>
        <div>
          <p className='m-0 ms-1'>シリーズ</p>
          <select className="form-control w-200" name="product" defaultValue={selectedProduct?.product_code} onChange={e => fetchProductVariationData(e.target.value)} >
            <option value="" />
            {
              productList?.map(product => (
                <option key={product.product_code} value={product.product_code}>{product.name}</option>
              ))
            }
          </select>
        </div>
      </div>
      <div className="stockTable d-flex justify-content-center flex-wrap mx-auto">
        {productVariationList?.map(productVariation => (
          <ProductItem key={productVariation.id} team={selectedTeam} product_code={selectedProduct?.product_code ?? ''} productValiation_id={productVariation.id} rarity_name={productVariation.rarity_name} variation_name={productVariation.variation_name} possession={productVariation.possession} is_possession={productVariation.is_possession} />
        ))}
      </div>
    </div>
  );
};

export default Album;