import axios from 'axios';

const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

interface Product {
  variation_id: number;
  rarity_name: string;
  product_id: string;
  product_name: string;
  team_name:string;
  variation_name: string;
  possession: number;
  is_print: boolean;
}

export interface Products {
  [productName: string]: Product[];
}

export const getPossesionProducts = async (): Promise<Products | null> => {
  try {
    // 現在のユーザーのデータを取得
    const response = await axios.get(`${apiUrl}/getPossessionProductValiationList/`, { withCredentials: true });
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}