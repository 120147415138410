import axios from 'axios';

const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

export interface Token {
  access: string;
  refresh: string;
}

export const refreshToken = async ():Promise<Token> => {
  const response = await axios.post(`${apiUrl}/auth/jwt/refresh`, {}, { withCredentials: true });
  return response.data;
}

export const verifyToken = async ():Promise<Boolean> => {
  const response = await axios.post(`${apiUrl}/auth/jwt/verify`, {}, { withCredentials: true });
  if(response.status === 200){
    return true;
  }else{
    return false;
  }
}

export const checkAdmin = async ():Promise<Boolean> => {
  const response = await axios.get(`${apiUrl}/check/admin`, { withCredentials: true });
  return response.data;
}