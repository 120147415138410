import React, { ReactElement, ReactNode, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../auth/AuthContext';

interface PublicRouteProps {
  children: ReactNode;
}

export const PublicRoute: React.FC<PublicRouteProps> = ({ children }): ReactElement | null => {
  const { authentication, authLoadingFlag } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authLoadingFlag && authentication) {
      navigate("/");
    }
  }, [authentication, navigate]);

  return !authentication ? <>{children}</> : null;
};
